import Cookies from "js-cookie";

//To Validate emailid
export function emailValidation(id, labelname, required, domain, otherdomain) {
  var txtstr = document.getElementById(id).value;
  txtstr = txtstr.replace(/[,;]$/, "");
  txtstr = txtstr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
  txtstr = txtstr.replace(/\s+/g, "");
  var i = 0;
  var foundspace = true;
  var replacestr = "";
  /* Trunkating space at first place */
  while (foundspace == true) {
    if (txtstr.charAt(i) != " ") {
      foundspace = false;
    } else if (txtstr.charAt(i) == " ") {
      replacestr = replacestr + txtstr.charAt(i);
    }
    i++;
  }
  txtstr = txtstr.replace(replacestr, "");
  document.getElementById(id).value = txtstr;
  var emailID = document.getElementById(id).value;
  var userdomain;
  if (required == "Y" || trim(emailID, "") != "") {
    var arr1 = emailID.split("@");

    if (arr1.length > 1) {
      emailID = trim(emailID, "");
      if (domain != "") {
        userdomain = emailID.substring(emailID.indexOf("@") + 1);
        if (echecks(emailID) == false) {
          return false;
        }
        if (
          domain.indexOf(userdomain) >= 0 ||
          otherdomain.indexOf(userdomain) >= 0
        ) {
        } else {
          return false;
        }
      } else {
        if (echecks(emailID) == false) {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  return true;
}

export function trim(str) {
  try {
    str = str.replace(/ /g, "");
  } catch (error) {}
  return str;
}

function echecks(str) {
  var reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
  var address = str;
  var i = 0;
  var foundspace = true;
  var replacestr = "";
  /* Trunkating space at first place */
  while (foundspace == true) {
    i = address.length;
    if (address.charAt(i) != " ") {
      foundspace = false;
    } else if (address.charAt(i) == " ") {
      replacestr = replacestr + address.charAt(i);
    }
    i--;
  }
  address = address.replace(replacestr, "");
  if (reg.test(address) == false) {
    return false;
  }
  return true;
}

//methods to get the geolocation of the user
export async function getUserGeolocationInfo() {
  var device = isMobile() ? "Mobile" : "Desktop";
  var browser = getBrowserinfo();
  var browsername = browser.split("/");

  var usrgeolocationdto = {};

  const data = await getGeolocationApiDetails();

  if (data.ip) {
    var usrgeolocationinfo =
      data.ip +
      "," +
      data.country_name +
      "," +
      data.city +
      "," +
      data.state_prov +
      "," +
      data.zipcode;
    var usragentinfo = browsername[0] + " " + browsername[1];

    usrgeolocationdto = {
      usrgeolocation: usrgeolocationinfo,
      usripaddress: data.ip,
      usergeoportal: device,
      usergeouseragent: usragentinfo,
      countrycode: data.country_code2,
      timezone: data.time_zone.name,
    };
  }

  return usrgeolocationdto;
}

async function getGeolocationApiDetails() {
  const url =
    "https://api.ipgeolocation.io/ipgeo?apiKey=" +
    process.env.NEXT_PUBLIC_GEO_API_KEY;

  const response = await fetch(url, {
    method: "GET",
    dataType: "json",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
}

function isMobile() {
  return /Mobi/.test(navigator.userAgent);
}

export function getBrowserinfo() {
  const userAgent = navigator.userAgent;
  var browser = "unkown";

  browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
  browser = /edg/i.test(userAgent) ? "Edge" : browser;
  browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
  browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
  browser =
    /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
      ? "Firefox"
      : browser;
  browser =
    /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
      ? "IE"
      : browser;
  browser =
    /chrome|crios/i.test(userAgent) &&
    !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? "Chrome"
      : browser;
  browser =
    /safari/i.test(userAgent) &&
    !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
      userAgent
    )
      ? "Safari"
      : browser;
  browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;

  if (browser == "UCBrowser") {
    var paramvalue = /(UCBrowser)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Edge") {
    var paramvalue = /(Edg)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "GoogleBot") {
    var paramvalue = /(GoogleBot)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Chromium") {
    var paramvalue = /(Chromium)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Firefox") {
    var paramvalue = /(Firefox)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Chrome") {
    var paramvalue = /(Chrome)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue) != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Safari") {
    var paramvalue = /(Safari)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "Opera") {
    var paramvalue = /(OPR)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  } else if (browser == "IE") {
    var paramvalue = /(Trident)\/([\d\.]+)/i;
    var version = "";
    if (userAgent.match(paramvalue)[2] != null) {
      version = userAgent.match(paramvalue)[2];
    }
    var result = browser + "/" + version;
    return result;
  }
}

//To Capitalize 1st letter
export function Capitalise(str) {
  if (str && str.charAt(0)) {
    const title = str.charAt(0).toUpperCase() + str.slice(1);
    return title;
  }
}

export function generateRandomId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function signalCheckOtherLanguageInput(strtext) {
  var punctRE = /[\u2000-\u206F\u2E00-\u2E7F]/g;
  var spaceRE = /\s+/g;
  var punctext = strtext.replace(punctRE, "").replace(spaceRE, " ");
  if (punctext.length != strtext.length) {
  }
  strtext = punctext;
  var rem = new RegExp("^[\x00-\x7F\xA0-\xFF]+$");
  for (var i = 0; i < strtext.length; i++) {
    var resultm = rem.test(strtext.charAt(i));
    if (resultm == false) {
      return false;
    }
  }

  return resultm;
}

//to get base64 url from the image
export function toDataURL(src, callback) {
  var dataURL = "";
  var image = new Image();
  image.crossOrigin = "Anonymous";
  image.onload = function () {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    context.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL("image/jpeg");
    callback(dataURL);
    return dataURL;
  };
  image.src = src;
}

export function trimWithFullWord(content, noOfCharacter) {
  var trimContent = content;
  if (!(name === undefined)) {
    noOfCharacter = parseInt(noOfCharacter, 10);
    if (content.length > 0) {
      content = content.trim();
      var cut = content.indexOf(" ", noOfCharacter);

      if (cut == -1 && content.indexOf(" ") != -1) {
        cut = content.length;
      }
      if (cut == -1) return content;
      trimContent = content.substring(0, cut);
      trimContent = trimContent.trim();
      if (trimContent.length > noOfCharacter) {
        var lastword = trimContent.lastIndexOf(" ");
        if (noOfCharacter - lastword < trimContent.length - noOfCharacter) {
          trimContent = trimContent.substring(0, lastword);
        }
        return trimContent + " ...";
      }
    }
  }
  return trimContent;
}

export function checkPublicDomain(emailid) {
  var cmnrepublicdomain =
    ",yahoo.com.ar,yahoo.com.au,yahoo.at,yahoo.be,yahoo.com.br,yahoo.en,yahoo.ca,yahoo.com.cn,yahoo.cn,yahoo.dk,yahoo.fi,yahoo.fr,yahoo.de,yahoo.gr,yahoo.com.hk,yahoo.co.in,yahoo.com,yahoo.ie,yahoo.co.il,yahoo.it,yahoo.co.jp,yahoo.co.kr,yahoo.com.my,yahoo.com.mx,yahoo.ae,yahoo.nl,yahoo.co.nz,yahoo.no,yahoo.com.ph,yahoo.pl,yahoo.pt,yahoo.ro,yahoo.ru,yahoo.com.sg,yahoo.co.za,yahoo.es,yahoo.se,yahoo.ch,yahoo.com.tw,yahoo.co.th,yahoo.com.tr,yahoo.co.uk,yahoo.com.vn,rocketmail.com,ymail.com,yahoo.in,gmail.com,hotmail.com,hotmail.it,live.com,live.in,msn.com,hotmail.fr,rediffmail.com,yahoo.co.id,yahoo.fr,hotmail.co.uk,";
  var domainchkft = trim(emailid, "");
  var domainchkftemp = domainchkft.split("@");
  if (cmnrepublicdomain.indexOf(domainchkftemp[1].toLowerCase()) >= 0) {
    return true;
  } else {
    return false;
  }
}

export const checkEmailValidation = (value) => {
  var reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;

  if (reg.test(value) == false) {
    return false;
  }

  return true;
};

export const getURLValue = (urlParam) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let value = urlParams.get(urlParam);
  if (value) {
    value = window.atob(value);
  }

  return value;
};

export const getExactURLValue = (urlParam) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let value = urlParams.get(urlParam);

  return value;
};

export const sendSystemMail = async (
  requesttype,
  emailid,
  title,
  pagename,
  casestudyid
) => {
  const url = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/hpsendmail.html";

  const data = new FormData();
  data.append("requesttype", requesttype);
  data.append("emailid", emailid);
  data.append("title", title);

  const response = await fetch(url, {
    method: "POST",
    body: data,
  });

  const result = await response.json();
};

export const ContactSalesApi = async (message, name, emailid, type) => {
  const url =
    process.env.NEXT_PUBLIC_REACT_APP_RDT_SERVICE_URL +
    "/rdt/saveuserrequestdetails";

  const data = {
    networkid: null,
    modulename: "OUTERPAGES",
    identifier: type,
    identifierid2: message,
    identifierid3: name,
    identifierid4: emailid,
    type: "NEW",
    userid: 0,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();
};

export const userTrack = async (email, action, pagename) => {
  let geoLocation = "";
  let geoUseragent = "";
  let geoPortal = "";
  const userLocationDetails = await getUserGeolocationInfo();
  if (userLocationDetails) {
    if (userLocationDetails.usrgeolocation) {
      geoLocation = userLocationDetails.usrgeolocation;
    }
    if (userLocationDetails.usergeouseragent) {
      geoUseragent = userLocationDetails.usergeouseragent;
    }
    if (userLocationDetails.usergeoportal) {
      geoPortal = userLocationDetails.usergeoportal;
    }
  }

  // const url = "https://ideastage.in/populatehptrackuseractivities.html";
  const url =
    process.env.NEXT_PUBLIC_SERVER_NAME_URL +
    "/populatehptrackuseractivities.html";
  const inputObj = {
    action: action,
    requesttype: "",
    pagename: window.location.href,
    geolocation: geoLocation,
    geouseragent: geoUseragent,
  };

  if (email) {
    inputObj.emailid = email !== "undefined" ? email : null;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputObj),
  });
};

export const ICSuserTrack = async (
  email,
  action,
  pagename,
  module,
  userId,
  accessId
) => {
  let geoLocation = "";
  let geoUseragent = "";
  let geoPortal = "";
  let ipAddress = "";
  const userLocationDetails = await getUserGeolocationInfo();
  if (userLocationDetails) {
    if (userLocationDetails.usrgeolocation) {
      geoLocation = userLocationDetails.usrgeolocation;
    }
    if (userLocationDetails.usergeouseragent) {
      geoUseragent = userLocationDetails.usergeouseragent;
    }
    if (userLocationDetails.usergeoportal) {
      geoPortal = userLocationDetails.usergeoportal;
    }
    if (userLocationDetails.usripaddress) {
      ipAddress = userLocationDetails.usripaddress;
    }
  }

  const url =
    process.env.NEXT_PUBLIC_REACT_APP_RDT_SERVICE_URL +
    "/rdt/interactivecustomerstory/interactivestoryusertrackid";
  // const url =process.env.NEXT_PUBLIC_SERVER_NAME_URL +"/populatehptrackuseractivities.html";
  const inputObj = {
    action: action,
    requesttype: "",
    pagename: pagename,
    geolocation: geoLocation,
    geouseragent: geoUseragent,
    ipaddress: ipAddress,
    geoportal: geoPortal,
    module: module,
  };

  if (email) {
    inputObj.emailid = email !== "undefined" ? email : null;
  }

  if (userId) {
    inputObj.userid = userId !== "undefined" ? userId : null;
  }

  if (accessId) {
    inputObj.accessid = accessId !== "undefined" ? accessId : null;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputObj),
  });
  const result = await response.json();
  Cookies.set("accessid", result.accessid);
  return result;
};

export const validateUserSession = async () => {
  const url = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/validateusersession";

  return await fetch(url, {
    method: "GET",
    credentials: "include",
  }).then((response) => response.text());
};

export const resendVerificationMail = async (emailId) => {
  const url =
    process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/resendverificationemail";
  const inputObj = {
    workemaild: emailId,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(inputObj),
  });

  const result = await response.text();
  return result;
};

export const checkURLValidity = (url) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = url;

    script.onload = () => {
      document.body.removeChild(script);
      resolve(true);
    };

    script.onerror = () => {
      document.body.removeChild(script);
      resolve(false);
    };

    document.body.appendChild(script);
  });
};

export const UploadToS3 = async (event, type) => {
  const userid = 11;
  const files = event;

  try {
    let imagePaths;

    if (type === "single") {
      const imagePath = await uploadFile(files, userid);
      imagePaths = [imagePath];
    } else if (type === "multiple") {
      imagePaths = await Promise.all(
        filesArr.map((item) => uploadFile(item, userid))
      );
      setMultipleFiles((prev) => {
        if (prev === undefined || prev === null) {
          return [...imagePaths];
        } else {
          return [...prev, ...imagePaths];
        }
      });
    } else {
      throw new Error("Invalid upload type");
    }

    return imagePaths;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
};

const uploadFile = async (file) => {
  const datac = new FormData();
  datac.append("file", file);
  datac.append("userid", 11);
  const url = process.env.NEXT_PUBLIC_REACT_APP_RDT_SERVICE_URL + "/s3/upload";

  const fetchObj = {
    method: "POST",
    body: datac,
    processData: false,
    contentType: false,
    timeout: 600000,
  };

  let s3Path = "";
  try {
    const response = await fetch(url, fetchObj);

    if (!response.ok) {
      throw new Error("Failed to upload file to S3");
    } else {
      const data = await response.text();
      s3Path = data;
    }
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw error;
  }

  return s3Path;
};

export const getFormatedTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const checkForUndefine = (value) => {
  let result;
  if (value) {
    result = value;
  }

  if (
    value == "NA" ||
    value == "N/A" ||
    value == "N/A [N/A]" ||
    value == "N/A[N/A]" ||
    value == "NA[NA]" ||
    value == "[NA]"
  ) {
    result = "NA";
  }

  return result;
};
