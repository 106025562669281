import React from "react";
import styled from "styled-components";
import { userTrack } from "../Common";
import { P } from "../CommonStyle";
import "./Footer.css";
import { OrangeOutlineBtn } from "../IPWebsiteRedesign/OrangeOutlineBtn";
import getConfig from "next/config";

const FooterWrap = styled.div`
  background-color: #555c67;
  height: auto;
  padding-top: 55px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 34px;

  @media screen and (max-width: 1280px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 35px;
    padding-right: 35px;
  }
`;
const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    row-gap: 35px;
    column-gap: 140px;
  }
`;
const TextWrap = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  @media screen and (max-width: 767px) {
    margin-top: 0 !important;
  }
`;
const FooterLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 80px;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    row-gap: 20px;
  }
`;
const SocialMedia = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 30px;
`;
const Svg = styled.svg`
  cursor: pointer;
`;
const FooterWdth = styled.div`
  width: 1209px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 1185px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
`;

const ExtendedOrangeOutlineBtn = styled(OrangeOutlineBtn)`
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
`;

const CopyRght = styled.div`
  @media screen and (max-width: 1280px) {
    margin-right: 50px;
  }
`;

export default function Footer(props) {
  const { publicRuntimeConfig } = getConfig();

  const AboutUs = async () => {
    await userTrack("", "Onclick of AboutUs in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "aboutus"
        : publicRuntimeConfig.assetPrefix + "/aboutus.html";
  };

  const Careers = async () => {
    await userTrack("", "Onclick of Careers in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/careers.html";
  };

  const MediaKit = async () => {
    await userTrack("", "Onclick of MediaKit in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/mediakit.html";
  };

  const ContactUs = async () => {
    await userTrack("", "Onclick of ContactUs in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/contactus"
        : publicRuntimeConfig.assetPrefix + "/contactus.html";
  };

  const PrivacyPolicy = async () => {
    await userTrack(
      "",
      "Onclick of PrivacyPolicy in Footer",
      "HomePage Footer"
    );
    window.location.href = "/privacy.html";
  };

  const TermsofUse = async () => {
    await userTrack("", "Onclick of TermsofUse in Footer", "HomePage Footer");
    window.location.href = "/terms.html";
  };

  const MarketAnalysis = async () => {
    await userTrack(
      "",
      "Onclick of MarketAnalysis in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/ResearchGoal/Market-Analysis"
        : publicRuntimeConfig.assetPrefix +
          "/ResearchGoal/Market-Analysis.html";
  };

  const TechnologyAnalysis = async () => {
    await userTrack(
      "",
      "Onclick of TechnologyAnalysis in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/ResearchGoal/Technology-Analysis"
        : publicRuntimeConfig.assetPrefix +
          "/ResearchGoal/Technology-Analysis.html";
  };

  const NewBusinessDevelopment = async () => {
    await userTrack(
      "",
      "Onclick of NewBusinessDevelopment in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/ResearchGoal/New-Business-Creation"
        : publicRuntimeConfig.assetPrefix +
          "/ResearchGoal/New-Business-Creation.html";
  };

  const NewProductDevelopment = async () => {
    await userTrack(
      "",
      "Onclick of NewProductDevelopment in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/ResearchGoal/New-Product-Development"
        : publicRuntimeConfig.assetPrefix +
          "/ResearchGoal/New-Product-Development.html";
  };

  const ApplicationUseCaseAnalysis = async () => {
    await userTrack(
      "",
      "Onclick of ApplicationUseCaseAnalysis in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/ResearchGoal/Use-Case-Analysis"
        : publicRuntimeConfig.assetPrefix +
          "/ResearchGoal/Use-Case-Analysis.html";
  };

  const Corporate = async () => {
    await userTrack(
      "",
      "Onclick of Corporate and Strategy in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Team/Corporate-Strategy"
        : publicRuntimeConfig.assetPrefix + "/Team/Corporate-Strategy.html";
  };

  const RandD = async () => {
    await userTrack("", "Onclick of RandD in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Team/R&D-and-Tech-Scouting"
        : publicRuntimeConfig.assetPrefix + "/Team/R&D-and-Tech-Scouting.html";
  };

  const Sales = async () => {
    await userTrack(
      "",
      "Onclick of Sales and Marketing in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Team/Sales-and-Marketing"
        : publicRuntimeConfig.assetPrefix + "/Team/Sales-and-Marketing.html";
  };

  const CVC = async () => {
    await userTrack("", "Onclick of CVC in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Team/CVC-and-M&A"
        : publicRuntimeConfig.assetPrefix + "/Team/CVC-and-M&A.html";
  };

  const NewBusiness = async () => {
    await userTrack("", "Onclick of NewBusiness in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Team/New-Business-Development"
        : publicRuntimeConfig.assetPrefix +
          "/Team/New-Business-Development.html";
  };

  const Mobility = async () => {
    await userTrack("", "Onclick of Mobility in Footer", "HomePage Footer");
    window.location.href =
      "/solution/opportunity-design/Mobility-And-Transportation.html";
  };

  const AdvancedMaterials = async () => {
    await userTrack(
      "",
      "Onclick of AdvancedMaterials in Footer",
      "HomePage Footer"
    );
    window.location.href =
      "/solution/opportunity-design/Advanced-Materials-And-Chemicals.html";
  };

  const Insurtech = async () => {
    await userTrack("", "Onclick of Insurtech in Footer", "HomePage Footer");
    window.location.href =
      "/solution/opportunity-design/Insurtech-And-Fintech.html";
  };

  const Sustainability = async () => {
    await userTrack(
      "",
      "Onclick of Sustainability in Footer",
      "HomePage Footer"
    );
    window.location.href =
      "/solution/opportunity-design/Sustainability-And-Cleantech.html";
  };

  const DeepTech = async () => {
    await userTrack("", "Onclick of DeepTech in Footer", "HomePage Footer");
    window.location.href = "/solution/opportunity-design/Deep-Tech.html";
  };

  const Health = async () => {
    await userTrack("", "Onclick of Health in Footer", "HomePage Footer");
    window.location.href =
      "/solution/opportunity-design/Health-And-Personal-Care.html";
  };

  const Manufacturing = async () => {
    await userTrack(
      "",
      "Onclick of Manufacturing in Footer",
      "HomePage Footer"
    );
    window.location.href =
      "/solution/opportunity-design/Manufacturing-And-Industrial.html";
  };

  const Enterprise = async () => {
    await userTrack("", "Onclick of Enterprise in Footer", "HomePage Footer");
    window.location.href =
      "/solution/opportunity-design/Enterprise-And-IT.html";
  };

  const Reports = async () => {
    await userTrack("", "Onclick of Reports in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/reports";
  };

  const Articles = async () => {
    await userTrack("", "Onclick of Articles in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/articles";
  };

  const CaseStudy = async () => {
    await userTrack("", "Onclick of CaseStudy in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL +
      "/growthleader/type/case-studies";
  };

  const Whitepaper = async () => {
    await userTrack("", "Onclick of Whitepaper in Footer", "HomePage Footer");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL +
      "/growthleader/type/white-papers";
  };

  const LatestEvents = async () => {
    await userTrack(
      "",
      "Onclick of Latest Events in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/ces-2025.html"; //"/CES2024Reports.html";
  };

  // CES2024Reports.html
  const HandleInteractiveCustomerStories = async () => {
    await userTrack("", "Onclick of InteractiveCustomerStories", "Homepage");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/interactivestories"
        : publicRuntimeConfig.assetPrefix + "/interactivestories.html";
  };

  const ContactSales = async () => {
    await userTrack("", "Onclick of ContactSales in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Contact-Sales"
        : publicRuntimeConfig.assetPrefix + "/Contact-Sales.html";
  };

  const PlatformClick = async () => {
    await userTrack("", "Onclick of Platform in Footer", "HomePage Footer");
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/AI-Business-Research-Platform"
        : publicRuntimeConfig.assetPrefix +
          "/AI-Business-Research-Platform.html";
  };

  const HandleResearchAssistantClick = async () => {
    await userTrack(
      "",
      "Onclick of Research Assistant in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Research-Assistant"
        : publicRuntimeConfig.assetPrefix + "/Research-Assistant.html";
  };

  const HandleResearchEliteClick = async () => {
    await userTrack(
      "",
      "Onclick of Learn More Research Elite in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Research-Elite"
        : publicRuntimeConfig.assetPrefix + "/Research-Elite.html";
  };

  const HandleResearchAIToolsClick = async () => {
    await userTrack(
      "",
      "Onclick of AI Research Tools in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/AI-Research-Tools"
        : publicRuntimeConfig.assetPrefix + "/AI-Research-Tools.html";
  };

  const HandleResearchTemplatesClick = async () => {
    await userTrack(
      "",
      "Onclick of AI Research Templates in Footer",
      "HomePage Footer"
    );
    window.location.href =
      process.env.NODE_ENV == "development"
        ? "/Research-Templates"
        : publicRuntimeConfig.assetPrefix + "/Research-Templates.html";
  };

  return (
    <div>
      <FooterWrap>
        <FooterWdth>
          <FooterContent>
            <div>
              <div className="FooterTitleBdrBtm">
                <P
                  text="Company"
                  color="#F9F9F9"
                  fontWeight="700"
                  fontSize="16px"
                  letterSpacing="0.8px"
                ></P>
              </div>
              <TextWrap>
                <div className="FooterLinkMarTop-25">
                  <P
                    text="About Us"
                    setHandleReqPop={props.setHandleReqPop}
                    onClick={AboutUs}
                    hoverColor="#f3e4d2"
                    color="#ECECEC"
                    //   fontSize="12px"
                    //   fontWeight="400"
                    //  letterSpacing="0.6px"
                    cursor="pointer"
                  ></P>
                </div>
                <P
                  text="Careers"
                  onClick={Careers}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  //   fontSize="12px"
                  //   fontWeight="400"
                  //  letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Media Kit"
                  onClick={MediaKit}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  //   fontSize="12px"
                  //   fontWeight="400"
                  //  letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Contact Us "
                  onClick={ContactUs}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Privacy Policy"
                  onClick={PrivacyPolicy}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Terms of Use"
                  onClick={TermsofUse}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  //   fontSize="12px"
                  //   fontWeight="400"
                  //  letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
              </TextWrap>
            </div>
            <div>
              <div className="FooterTitleBdrBtm">
                <P
                  text="Platform"
                  color="#F9F9F9"
                  fontWeight="700"
                  fontSize="16px"
                  letterSpacing="0.8px"
                ></P>
              </div>
              <TextWrap>
                <div className="FooterLinkMarTop-25">
                  <P
                    text="Platform"
                    color="#ECECEC"
                    hoverColor="#f3e4d2"
                    // fontSize="12px"
                    // fontWeight="400"
                    // letterSpacing="0.6px"
                    onClick={PlatformClick}
                    cursor="pointer"
                  ></P>
                </div>

                <P
                  text="Research Assistant"
                  onClick={HandleResearchAssistantClick}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Research Elite"
                  onClick={HandleResearchEliteClick}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="AI Research Tools"
                  onClick={HandleResearchAIToolsClick}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Templates"
                  onClick={HandleResearchTemplatesClick}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
              </TextWrap>
            </div>
            <div>
              <div className="FooterTitleBdrBtmLongWidth">
                <P
                  text="Solutions"
                  color="#F9F9F9"
                  fontWeight="700"
                  fontSize="16px"
                  letterSpacing="0.8px"
                ></P>
              </div>
              <TextWrap>
                <div className="FooterLinkMarTop-25">
                  <P
                    text="By Research Goal"
                    color="#ECECEC"
                    fontSize="14px"
                    // fontWeight="400"
                    // letterSpacing="0.6px"
                    textDecoration="underline"
                  ></P>
                </div>
                <P
                  text="Market Analysis"
                  onClick={MarketAnalysis}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Technology Analysis"
                  onClick={TechnologyAnalysis}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="New Business Creation"
                  onClick={NewBusinessDevelopment}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="New Product Development"
                  onClick={NewProductDevelopment}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Application Use Case Analysis"
                  onClick={ApplicationUseCaseAnalysis}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
              </TextWrap>
            </div>
            <div>
              <TextWrap style={{ marginTop: "55px" }}>
                <P
                  text="By Team"
                  color="#ECECEC"
                  fontSize="14px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  textDecoration="underline"
                ></P>
                <P
                  text="Corporate and Strategy"
                  onClick={Corporate}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="R&D and Technology Scouting"
                  onClick={RandD}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  fontSize="12px"
                  fontWeight="400"
                  letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Sales and Marketing"
                  onClick={Sales}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="CVC and M&A"
                  onClick={CVC}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="New Business Development"
                  onClick={NewBusiness}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
              </TextWrap>
            </div>
            <div className="VisibilityHideDesktop FullHideMobile">
              <TextWrap style={{ marginTop: "55px" }}>
                <P
                  text="Opportunity Design"
                  color="#ECECEC"
                  fontSize="14px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  textDecoration="underline"
                ></P>
                <P
                  text="Mobility & Transportation"
                  onClick={Mobility}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Advanced Materials and Chemicals"
                  onClick={AdvancedMaterials}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Insurtech and Fintech"
                  onClick={Insurtech}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Sustainability & Cleantech"
                  onClick={Sustainability}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Deep Tech"
                  color="#ECECEC"
                  onClick={DeepTech}
                  // fontSize="12px"
                  // fontWeight="400"
                  hoverColor="#f3e4d2"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Health & Personal Care"
                  onClick={Health}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Manufacturing & Industrial"
                  onClick={Manufacturing}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Enterprise and IT"
                  onClick={Enterprise}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
              </TextWrap>
            </div>
            <div>
              <P
                text="Resources"
                color="#F9F9F9"
                fontWeight="700"
                fontSize="16px"
                letterSpacing="0.8px"
                mobMarginBottom="30px"
              ></P>
              <TextWrap>
                <P
                  text="Interactive Customer stories"
                  onClick={HandleInteractiveCustomerStories}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>

                <P
                  text="Reports"
                  onClick={Reports}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Articles"
                  onClick={Articles}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Case Study"
                  onClick={CaseStudy}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Whitepaper"
                  onClick={Whitepaper}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <P
                  text="Latest Events"
                  onClick={LatestEvents}
                  color="#ECECEC"
                  hoverColor="#f3e4d2"
                  // fontSize="12px"
                  // fontWeight="400"
                  // letterSpacing="0.6px"
                  cursor="pointer"
                ></P>
                <div>
                  <ExtendedOrangeOutlineBtn
                    onClick={ContactSales}
                    width="140px"
                    height="40px"
                  >
                    Contact Sales
                  </ExtendedOrangeOutlineBtn>
                </div>
              </TextWrap>
            </div>
          </FooterContent>

          <FooterLink>
            <div>
              <P
                text="Follow us"
                color="#F9F9F9"
                fontSize="16px"
                fontWeight="700"
                letterSpacing="0.8px"
              ></P>
              <SocialMedia>
                <a
                  href="https://www.facebook.com/ideapoke"
                  target="_blank"
                  aria-label="facebook"
                >
                  <Svg
                    className="svgColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 0.0400391C4.5 0.0400391 0 4.53004 0 10.06C0 15.06 3.66 19.21 8.44 19.96V12.96H5.9V10.06H8.44V7.85004C8.44 5.34004 9.93 3.96004 12.22 3.96004C13.31 3.96004 14.45 4.15004 14.45 4.15004V6.62004H13.19C11.95 6.62004 11.56 7.39004 11.56 8.18004V10.06H14.34L13.89 12.96H11.56V19.96C13.9164 19.5879 16.0622 18.3856 17.6099 16.5701C19.1576 14.7546 20.0054 12.4457 20 10.06C20 4.53004 15.5 0.0400391 10 0.0400391Z"
                      fill="white"
                    />
                  </Svg>
                </a>
                <a
                  href="http://www.linkedin.com/company/ideapoke"
                  target="_blank"
                  aria-label="linkedin"
                >
                  <Svg
                    className="svgColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z"
                      fill="#F9F9F9"
                    />
                  </Svg>
                </a>
                <a
                  href="https://twitter.com/ideapoke"
                  target="_blank"
                  aria-label="twitter"
                >
                  <svg
                    className="svgColor"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7236 2.0625H19.7559L13.1312 9.63417L20.9247 19.9375H14.8224L10.0429 13.6886L4.57408 19.9375H1.53991L8.62575 11.8388L1.14941 2.0625H7.40658L11.7268 7.77425L16.7236 2.0625ZM15.6593 18.1225H17.3396L6.49358 3.78217H4.6905L15.6593 18.1225Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.quora.com/profile/Ideapoke-1"
                  target="_blank"
                  aria-label="quora"
                >
                  <Svg
                    className="svgColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 13.96H15.33C15.27 14.48 14.93 15.13 14.08 15.13C13.3 15.13 12.74 14.59 12.2 13.77C13.0917 13.0883 13.8126 12.2085 14.3055 11.2001C14.7984 10.1917 15.0499 9.08239 15.04 7.96C15.04 3.5 11.36 0.5 7.51 0.5C3.71 0.5 0 3.53 0 7.97C0 12.37 3.71 15.4 7.51 15.4C8.17 15.4 8.83 15.31 9.46 15.14C10.2 16.41 11.19 17.5 13.06 17.5C16.16 17.5 16.51 14.64 16.44 13.96ZM10.99 11.78C10.26 10.67 9.33 9.8 7.53 9.8C6.37 9.8 5.47 10.18 4.91 10.66L5.37 11.58C5.61 11.47 5.86 11.43 6.12 11.43C7.47 11.43 8.16 12.6 8.75 13.76C8.37 13.87 7.96 13.92 7.51 13.92C4.66 13.92 3.43 11.91 3.43 7.97C3.43 4.01 4.66 1.98 7.51 1.98C10.4 1.98 11.64 4.01 11.64 7.97C11.63 9.55 11.43 10.83 10.99 11.78Z"
                      fill="white"
                    />
                  </Svg>
                </a>
                <a
                  href="https://www.instagram.com/ideapoke/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <Svg
                    className="svgColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
                      fill="#F9F9F9"
                    />
                  </Svg>
                </a>
              </SocialMedia>
            </div>
            <CopyRght>
              <P
                text="© Copyright 2024 Ideapoke. All Rights Reserved."
                color="#F9F9F9"
                fontSize="12px"
                fontWeight="700"
                letterSpacing="0.6px"
              ></P>
            </CopyRght>
          </FooterLink>
        </FooterWdth>
      </FooterWrap>
    </div>
  );
}
